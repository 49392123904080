import { Column, Container, Grid, Section } from '../components/styled/layout'
import React, { Component } from 'react'

import BreadCrumbs from '../components/UI/BreadCrumbs'
import Img from 'gatsby-image'
import JoinNewsletter from '../components/UI/JoinNewsletter'
import Layout from '../components/layout'
import SEO from '../components/SEO/SEO'
import { Title } from '../components/styled/typography'
import { graphql } from 'gatsby'
import styled from 'styled-components'

const HtmlWrapper = styled.div`
  & strong {
    font-family: 'Roboto slab', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    & strong {
      font-size: 1rem;
    }
  }
`

class NyheterTemplate extends Component {
  state = {}

  render() {
    const post = this.props.data.airtable.data
    const { location } = this.props
    console.log(location)
    return (
      <React.Fragment>
        <SEO title={post.Titel} />
        <Layout>
          <BreadCrumbs />
          <Container>
            <Section>
              <Grid columns="1fr">
                <Column>
                  <Title style={{ marginBottom: '0px' }}>{post.Rubrik}</Title>
                </Column>
                <Column>
                  <Img
                    fluid={post.Bilder.localFiles[0].childImageSharp.fluid}
                  />
                </Column>
                <Column maxWidth="950px" style={{ margin: '0 auto' }}>
                  <HtmlWrapper
                    dangerouslySetInnerHTML={{
                      __html: post.Inneh_ll.childMarkdownRemark.html,
                    }}
                  />
                </Column>
              </Grid>
            </Section>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

export default NyheterTemplate

export const pageQuery = graphql`
  query NewsByID($id: String!) {
    airtable(id: { eq: $id }) {
      data {
        Rubrik
        Path
        Inneh_ll {
          childMarkdownRemark {
            html
          }
        }
        Bilder {
          localFiles {
            childImageSharp {
              fluid(
                maxWidth: 1920
                maxHeight: 900
                cropFocus: CENTER
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
